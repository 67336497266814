function cookieInit() {
  function setCookie(cName, cValue, expDays) {
    let date = new Date()
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + date.toUTCString()
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/'
  }

  function getCookie(cName) {
    const name = cName + '='
    const cCoded = decodeURIComponent(document.cookie)
    const cArr = cCoded.split('; ')
    let value
    cArr.forEach(val => {
      if (val.indexOf(name) === 0) value = val.substring(name.length)
    })

    return value
  }

  const consents = {}
  const consentsCheckbox = document.querySelectorAll('#consents input');
  let playerWarning = document.querySelectorAll('.cookies-warning-player');

  const hideCookieWarning = (value) => {
    if (value.length > 0) {
      value.forEach(element => {
        element.classList.add('hidden');
      })
    }
  }

  const showCookieWarning = (value) => {
    if (value.length > 0) {
      value.forEach(element => {
        element.classList.remove('hidden');
      })
    }
  }

  const hidePadding = (value) => {
    if (value.length > 0) {
      value.forEach(element => {
        element.classList.add('hide-padding');
      })
    }
  }

  const showPadding = (value) => {
    if (value.length > 0) {
      value.forEach(element => {
        element.classList.remove('hide-padding');
      })
    }
  }

  const googleScripts = () => {
    let headScript = document.head.querySelectorAll(
      'script[type="cookie-consent/google"]'
    )
    if (headScript.length > 0) {
      headScript.forEach(script => {
        let headScriptInner = script.innerHTML;
        let newHeadScript = document.createElement('script');

        script.remove();
        newHeadScript.innerHTML = headScriptInner;
        document.head.appendChild(newHeadScript);
      })
    }
  }

  const facebookScript = () => {
    let headScript = document.head.querySelectorAll(
      'script[type="cookie-consent/facebook"]'
    )
    let headNoScript = document.body.querySelectorAll(
      'noscript[type="cookie-consent/facebook"]'
    )

    if (headScript.length > 0) {
      headScript.forEach(script => {
        let headScriptInner = script.innerHTML
        let newHeadScript = document.createElement('script')

        newHeadScript.innerHTML = headScriptInner
        document.head.appendChild(newHeadScript)
        script.remove()
      })
    }

    if (headNoScript.length > 0) {
      headNoScript.forEach(script => {
        let headNoScriptInner = script.innerHTML
        let newHeadNoScript = document.createElement('noscript')

        newHeadNoScript.innerHTML = headNoScriptInner
        document.body.appendChild(newHeadNoScript)
        script.remove()
      })
    }
  }

  const recaptchaScripts = () => {
    let headScript = document.body.querySelectorAll(
      'script[type="cookie-consent/recaptcha"]'
    )

    if (headScript.length > 0) {
      headScript.forEach(script => {
        let headScriptInner = script.src;
        let newHeadScript = document.createElement('script');

        newHeadScript.setAttribute('src', `${headScriptInner}`);
        document.body.appendChild(newHeadScript);
        script.remove();
      })
    }
  }

  const playersScripts = () => {
    let youtubeScripts = document.body.querySelectorAll(
      'iframe[data-youtube-src]'
    )

    let slideScripts = document.body.querySelectorAll(
      'iframe[data-slide-src]'
    )

    let faceVideoScripts = document.body.querySelectorAll(
      'iframe[data-facebook-src]'
    )

    if (youtubeScripts.length > 0) {
      youtubeScripts.forEach(script => {
        let ytScriptInner = script.getAttribute('data-youtube-src');

        script.setAttribute('src', `${ytScriptInner}`)
        script.removeAttribute('data-youtube-src');
      })
    }

    if (slideScripts.length > 0) {
      slideScripts.forEach(script => {
        let slideScriptInner = script.getAttribute('data-slide-src');

        script.setAttribute('src', `${slideScriptInner}`)
        script.removeAttribute('data-slide-src');
      })
    }

    if (faceVideoScripts.length > 0) {
      faceVideoScripts.forEach(script => {
        let fbScriptInner = script.getAttribute('data-facebook-src');

        script.setAttribute('src', `${fbScriptInner}`)
        script.removeAttribute('data-facebook-src');
      })
    }
  }

  // case 'facebook':
  //   facebookScript()
  //   break

  const pushScript = () => {
    const consents = JSON.parse(getCookie('cookie-masner'))
    Object.keys(consents).map(consent => {
      switch (consent) {
        case 'google':
          googleScripts()
          break
        case 'players':
          playersScripts()
          break
        case 'recaptcha':
          recaptchaScripts()
          break
        default:
          break
      }
    })

    for (var key in consents) {
      if (consents.hasOwnProperty(key)) {
        if (consents[key] === true) {
          document.querySelector(`input[data-conset="${key}"]`).checked = true
        }
      }
    }

    if (consents.players === true) {
      hideCookieWarning(playerWarning)
      showPadding(document.querySelectorAll('.product-info-list .content.youtube'))
      showPadding(document.querySelectorAll('.product-info-list .multiple-content-wrapper'))
      showPadding(document.querySelectorAll('.product-info-list .content'))
    } else {
      showCookieWarning(playerWarning)
      hidePadding(document.querySelectorAll('.product-info-list .content.youtube'))
      hidePadding(document.querySelectorAll('.product-info-list .multiple-content-wrapper'))
      hidePadding(document.querySelectorAll('.product-info-list .content'))
    }
  }

  const checkSettings = () => {
    document.querySelector('.cookies').classList.add('is-hidden');
    document.querySelector('.cookies-settings').classList.add('is-hidden');
    document.body.style.overflow = 'auto'

    const consents = {}

    consentsCheckbox.forEach(checkbox => {
      if (checkbox.checked) {
        const consetName = checkbox.getAttribute('data-conset');

        consents[consetName] = true
      }
    })

    setCookie('cookie-masner', JSON.stringify(consents), 30);
    pushScript()
  }

  const checkAll = () => {
    document.querySelector('.cookies').classList.add('is-hidden');
    document.querySelector('.cookies-settings').classList.add('is-hidden');
    document.body.style.overflow = 'auto'

    const consents = {}

    consentsCheckbox.forEach(checkbox => {
      checkbox.setAttribute('checked', 'true');
      const consetName = checkbox.getAttribute('data-conset');

      consents[consetName] = true
    })
    setCookie('cookie-masner', JSON.stringify(consents), 30);
    pushScript()
  }

  document.querySelector('#show-extended').addEventListener('click', (e) => {
    e.preventDefault;
    document.querySelector('.cookies').classList.add('is-hidden');
    document.querySelector('.cookies-settings').classList.remove('is-hidden');
  })

  document.querySelectorAll('#cookies-btn-save-all').forEach(button => {
    button.addEventListener('click', () => {
      checkAll()
    })
  })

  document.querySelector('#closed').addEventListener('click', (e) => {
    e.preventDefault;
    document.querySelector('.cookies-settings').classList.add('is-hidden');
    document.querySelector('.cookies').classList.add('is-hidden');
    document.body.classList.remove('no-scroll')
  })

  document.querySelectorAll('#cookies-btn-accept-my-setting').forEach(button => {
    button.addEventListener('click', () => {
      checkSettings()
    })
  })

  document.querySelectorAll('#cookies-btn-save-nessesery').forEach(button => {
    button.addEventListener('click', () => {
      checkSettings()
    })
  })

  function cookieMess() {
    if (!getCookie('cookie-masner')) {
      document.querySelector('.cookies').classList.remove('is-hidden');
      document.body.classList.add('no-scroll');
    } else {
      pushScript()
    }
  }

  window.addEventListener('load', cookieMess)

  if (document.querySelector('.cookies-b-activator')) {
    document.querySelectorAll('.cookies-b-activator').forEach(button => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        document.querySelector('.cookies').classList.remove('is-hidden');
        document.body.classList.add('no-scroll');
      })
    })
  }
}
cookieInit()