function showBlogTerms() {
 let blogTermsContent = document.querySelectorAll('.terms');
 if(!blogTermsContent.length) {
  return
 }
 blogTermsContent.forEach(element => {
  element.addEventListener('click', () => {
   element.classList.add('active')
  })
 })
}
showBlogTerms()