function relatedPostsSlider() {
 let relatedPosts = document.querySelectorAll('.related-posts');
 if (!relatedPosts.length) {
  return
 }
 for (let i = 0; i < relatedPosts.length; i++) {
  let sliderList = relatedPosts[i].querySelector('.related-posts-list');
  let sliderItems = sliderList.querySelectorAll('.related-posts-list .post-content');
  let next = relatedPosts[i].querySelector('.slick-next');
  let prev = relatedPosts[i].querySelector('.slick-prev');
  let slideWidth = sliderList.querySelector('.related-posts-list .post-content').getBoundingClientRect().width + 20;
  let slide = 0;
  let firtsMediaQ = window.matchMedia('(min-width: 501px) and (max-width: 767px)');
  let firtsMediaQStep = window.matchMedia('(max-width: 767px)');
  let secondMediaQ = window.matchMedia('(max-width: 500px)');

  function counterStep() {
   if (firtsMediaQStep.matches) {
    return 1;
   } else {
    return 2;
   }
  }

  function counterSlide() {
   if (firtsMediaQ.matches) {
    return 2;
   } else if (secondMediaQ.matches) {
    return 1;
   } else {
    return (sliderItems.length - 1);
   }
  }

  function slideMove() {
   sliderList.style.transform = `translateX(${-(slide * counterStep()) * slideWidth}px)`;
  }

  next.addEventListener('click', function (e) {
   e.preventDefault();
   slide++;

   if (slide > sliderItems.length - counterSlide()) {
    slide = 0;
   }

   slideMove()
  })

  prev.addEventListener('click', function (e) {
   e.preventDefault();
   slide--;

   if (slide < 0) {
    slide = sliderItems.length - counterSlide();
   }

   slideMove()
  })
 }
}

relatedPostsSlider()