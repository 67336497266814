/*
* Tab Slider
*/
function sliderTabs() {
 let sliderContainer = document.querySelectorAll('.ss-slider');
 if (!sliderContainer.length) {
  return
 }
 for (let i = 0; i < sliderContainer.length; i++) {
  let sliderList = sliderContainer[i].querySelector('.slider');
  let sliderItems = sliderList.querySelectorAll('.slider LI');
  let next = sliderContainer[i].querySelector('.next');
  let prev = sliderContainer[i].querySelector('.prev');
  let slideWidth = sliderList.querySelector('.slider LI').getBoundingClientRect().width;
  let slide = 0;
  let queryMedia = window.matchMedia('(max-width: 600px)');

  if (queryMedia.matches) {
   if (sliderItems.length < 1) {
    next.style.display = 'none';
    prev.style.display = 'none';
   }
  } else {
   if (sliderItems.length < 3) {
    next.style.display = 'none';
    prev.style.display = 'none';
   }
  }

  function counterSlide() {
   if (queryMedia.matches) {
    return 1;
   } else {
    return 2;
   }
  }

  function slideMove() {
   sliderList.style.transform = `translateX(${-slide * slideWidth}px)`;
  }

  next.addEventListener('click', function (e) {
   e.preventDefault();
   slide++;

   if (slide > sliderItems.length - counterSlide()) {
    slide = 0;
   }

   slideMove()
  })

  prev.addEventListener('click', function (e) {
   e.preventDefault();
   slide--;

   if (slide < 0) {
    slide = sliderItems.length - counterSlide();
   }

   slideMove()
  })
 }
}
sliderTabs()