function blogMenuMobile() {
 let mobileMenu = document.querySelector('.mobile-blog-header');
 if (!mobileMenu) {
  return
 }

 let blogMenuContent = mobileMenu.querySelector('.mobile-menu-items');
 let blogToggleBtn = mobileMenu.querySelector('.hamburger-menu');
 let menuHidden = mobileMenu.querySelectorAll('.has-child');
 let toggleSubMenu = mobileMenu.querySelectorAll('.submenu');
 blogToggleBtn.addEventListener('click', (e) => {
  e.preventDefault();
  blogToggleBtn.querySelector('i').classList.toggle('fa-xmark');
  blogMenuContent.classList.toggle('open');
 })

 menuHidden.forEach((button, index) => {
  button.addEventListener('click', (e) => {
   e.preventDefault();
   menuHidden[index].classList.toggle('active');
   toggleSubMenu[index].classList.toggle('open');
  })
 });
}
blogMenuMobile()